import React from 'react'
import { graphql } from 'gatsby'
import Blog from '@components/blog/blog'
import Layout from '@components/layout/rootLayout'

const BlogIndex = props => {
  const {
    pageContext: { locale }
  } = props
  return (
    <Layout locale={locale}>
      <Blog {...props} />
    </Layout>
  )
}

export default BlogIndex

export const query = graphql`
  query ListQuery($locale: String) {
    allMdx(
      filter: {
        frontmatter: { lang: { eq: $locale }, tags: { nin: "invisible" } }
        fields: { categories: { nin: ["arcpanel"] } }
        internal: { contentFilePath: { regex: "/blog/" }}
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM Do YYYY")
            title
            description
            excerpt
            cover_image {
              publicURL
              childImageSharp {
                resize(width: 1500, height: 1500) {
                  src
                }
                gatsbyImageData(
                  width: 500
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                  layout: CONSTRAINED
                )
              }
            }
          }
          fields {
            locale
            slug
            categories
          }
        }
      }
    }
  }
`
